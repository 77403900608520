import * as THREE from 'three';
import {OrbitControls, RGBELoader, GLTFLoader} from 'three/examples/jsm/Addons.js';
import wmap from '../Textures/wood.jpg';
const renderer = new THREE.WebGLRenderer({antialias:true});
renderer.shadowMap.enabled=true;
renderer.setClearColor=0x000000;
renderer.setSize(window.innerWidth,window.innerHeight);
renderer.setPixelRatio(window.setPixelRatio);
document.body.appendChild(renderer.domElement);

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth/window.innerHeight,
    0.1,
    100000
);


const orbit = new OrbitControls(camera,renderer.domElement);
camera.position.set(5,10,-10);
orbit.update();
const hdrTextureURL = new URL('../img/brown_photostudio_04_8k.hdr',import.meta.url);
//src\img\brown_photostudio_04_8k.hdr
renderer.outputEncoding  = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure =1.8;
const loader = new RGBELoader();
loader.load(hdrTextureURL, function(texture){
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.background=texture;
    scene.environment=texture;
    scene.environment.castShadow=true;
});

//____________________________________________________________________________________


let wmapp = new THREE.TextureLoader().load(wmap);
const ExpMat = new THREE.MeshPhysicalMaterial(
    {
        //color:0x049ef4,
        color:0x444444,
        reflectivity:0,
        clearcoat:0.3,
        clearcoatRoughness:0.2,
        map:wmapp,
        roughness:10,
        metalness:0
    });
//_______________________________________________________________________________________________________________
const monkeyURL = new URL('../models/2monkey.glb',import.meta.url);
const assetLoader = new GLTFLoader();
assetLoader.load(monkeyURL.href, function(gltf){
    const model = gltf.scene;
    scene.add(model);
    console.log(model.getObjectByName('Suzanne').material);
    // model.getObjectByName('Suzanne').material.color.setHex(0xff0000); //Works
    model.getObjectByName('Suzanne').material=ExpMat;
    model.getObjectByName('Suzanne').castShadow=true;
    model.getObjectByName('Suzanne').receiveShadow=true;
    model.getObjectByName('Suzanne').scale.set(5,5,5);
    model.position.set(0,0,0);
});
const directionalL = new THREE.DirectionalLight(0xffffff,10);
scene.add(directionalL);
directionalL.position.set(30,2,10);
const DL1_Helper = new THREE.DirectionalLightHelper(directionalL,10,0xff0000);

directionalL.castShadow=true;

const directionalL2 = new THREE.DirectionalLight(0xffffff,5);

directionalL2.position.set(0,2,30);
directionalL2.castShadow=true;
scene.add(directionalL2);
const DL2_Helper = new THREE.DirectionalLightHelper(directionalL2,10,0x0000ff);

const ambient_light = new THREE.AmbientLight(0xffffff,3);
scene.add(ambient_light);
// scene.add(DL1_Helper);
// scene.add(DL2_Helper);
// const helper = new THREE.AxesHelper(100);
// scene.add(helper);
function animate(time){
    renderer.render(scene,camera);
}

renderer.setAnimationLoop(animate);
window.addEventListener('resize',function(){
    camera.aspect=(window.innerWidth/window.innerHeight);
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth,window.innerHeight);
})